.MuiFormControlLabel-label{font-size: 14px!important;}

body,html,.app,#root,.container{height:99%}
body{padding:0;margin:0;
    background:#efefef;
    color:#0000008a;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 13px;
}

.bg-blue{background: #4051b5;}

h2{padding: 0 0 0 34px; margin: 0; font-size: 20px; position: relative;}
h2 span{font-size: 12px; display: block;}
h2 .material-icons{position: absolute; left:6px; top:0}

.note{background:#f1f1f1;padding:2px 10px; font-size: 11px;}

.appBar{flex-grow: 1;}
.appBar h6{flex-grow: 1;}
.appBar .logo{ font-size: 49px;
  position: absolute;
  top: 8px;}
.appBar .app-name{margin-left: 62px;}

.left-menu{width: 250px;}

.build-version{
  position: absolute;
  color: #f1f1f1;
  right: 6px;
  bottom: 3px;
  z-index: 10;
  font-size: 11px;
}

.container{ padding: 14px 10px 10px 10px; }
.section{ padding:8px 10px;  }

.grid-detail {}
.grid-detail .MuiGrid-item{}
.grid-detail span{font-weight: bold;}


.mt-10{ margin-top: 10px;}
.mt-12{ margin-top: 12px;}
.mt-14{ margin-top: 14px;}
.mb-10{ margin-bottom: 10px;}
.mb-12{ margin-bottom: 12px;}
.mb-14{ margin-bottom: 14px;}


.btn-bar{min-width:50px!important; margin-right:4px!important; zoom: 0.9;}
.btn-group{position: relative; overflow: auto; min-height: 30px;}
.btn-group-aligh-right{position: absolute; right:8px}

.icon-sub{zoom: 0.6; margin-top:15px}
.icon-green{color:seagreen}
.icon-blue{color:#3f51b5}
.icon-red{color:red}



.footer {
  padding: 4px 10px;
  background: #3f51b5;
  position: fixed;
  width: 100%;
  bottom: 0;
  font-size: 9px;
}
.footer .copy-right{ color:#fff}

.login-wrapper{height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}
.login {
    
}
.login .logo{text-align: center;}

.grid-custom-cell{width:100%;}
.grid-custom-cell .i{padding-bottom: 6px;}
.grid-custom-cell .u{ font-size: 11px;}


.progress-bar{
  margin-top:-4px
}
.breadcrumbs {
  position: absolute;
  background: #e1e1e1;
  padding: 0px 6px;
  border-radius: 5px;
  right: 6px;
  z-index: 100;
}
.breadcrumbs a{font-size: 13px;}
.breadcrumbs .MuiBreadcrumbs-separator{margin:0 2px}


/* refresh timer animation*/
.ctimer-container{position: relative;color:#e1e1e1;}
.ctimer{ position: absolute; left: 62px; top: 0; }
.ctimer svg {
  height:40px;
}
.ctimer #clockface {
  fill: silver;
}
.ctimer #spent {
  fill:none;
  stroke: #6683c2;
  stroke-width: 38px;
  stroke-dasharray: 119.397px;
  stroke-dashoffset: 119.397px;
}
@keyframes ctimer-animate  {
  to {
    stroke-dashoffset: 0;
  }
}

.modal-dialog{}
.modal-dialog h2{font-weight: normal;}
.modal-dialog h2 .material-icons{top:4px;left:0}

/*  custom paging */
.custom-paging{position: relative; overflow: auto; border: 1px solid #e1e1e1; background:#fafafa; padding:2px}
.custom-paging-list{float:right}

@media only screen and (max-width: 600px) {
  .appBar .app-name{margin-left: 50px;}
  .appBar .logo{ font-size: 39px;} 
  
}

.graph{ position: relative }
.graph svg{background:#fff;z-index: 100;}
.graph-tooltip{ background:#36b5ff; padding: 4px 6px; border-radius: 5px; color:#fff; font-size: 11px;}
.graph-loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 49%;
  left: 49%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.dashboard{}
.dashboard .box{position: relative; overflow: hidden; height: 74px; padding: 16px 16px;}
.dashboard .box h1{font-size: 32px;}
.dashboard .box .title{ float: right;}
.dashboard .box .value{font-size: 30px; position: absolute; right: 10px; top: 50px;}
.dashboard .box .material-icons{zoom:2}


.site-loader{height:100%;display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;}
.site-loader .wrapper{
    color: #888;
}

.site-loader .wrapper .logo-icon{zoom: 8;text-align: center; color:#3f51b5}
.site-loader .MuiLinearProgress-root{width:250px}

.round-container{
    background: white;
    padding: 20px 20px 20px 20px;
    border-radius: 10px;
}

.live{}
.live .live-activity th{font-size: 11px;}
.live .live-activity td{font-size: 10px;}
.live .status{
  padding: 2px 5px;
  border-radius: 7px;
  color: #fff;}
.CONNECTED{ background: #1db326;}
.DISCONNECTED {background: #959595;  }
.ERROR {background: #ff0000;}
.FETCH_QUEUE {background: #03a9f4;}
.IDLE{background:#f3aa12}


.islive {
  letter-spacing: 6px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: bold;
  padding:2px 4px 2px 8px;
  position: absolute;
  right: 10px;
  background: #f62749bd;
  animation: islive 1s ease infinite;
  -moz-animation: islive 1s ease infinite;
  -webkit-animation: islive 1s ease infinite;
}
/*.timer-on{
  animation: timer-on 1s ease infinite;
  -moz-animation: timer-on 1s ease infinite;
  -webkit-animation: timer-on 1s ease infinite; 
}

@keyframes timer-on {
  0%,100% {
    color: #87b58a;
  }
  50% {
    color: #08ba13;
    text-shadow: 0 0 .1vw #4fe859;
  }
}*/


@keyframes islive {
  0%,
  100% {
    text-shadow: 0 0 .2vw #FA1C16, 0 0 .4vw #FA1C16, 0 0 .6vw #FA1C16, 0 0 .8vw #FA1C16, 0 0 .4vw #FED128, 0 0 .1vw #806914;
    color: #fff;
  }
  50% {
    text-shadow: 0 0 .1vw #ff3630, 0 0 .4vw #ff3630, 0 0 .6vw #800E0B, 0 0 5vw #800E0B, 0 0 .2vw #800E0B, 0 0 .1vw #40340A;
    color: #e66460;
  }
}

